'use client'
import React, { useEffect } from 'react'
interface CookieProProps {
  nonce: string
}

const CookiePro: React.FC<CookieProProps> = ({ nonce }) => {
  useEffect(() => {
    const loadCookieProScript = () => {
      const cookieProScript = document.createElement('script')
      cookieProScript.src = 'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js'
      cookieProScript.type = 'text/javascript'
      cookieProScript.charset = 'UTF-8'
      cookieProScript.setAttribute('data-domain-script', '2380041c-a589-4803-afde-218f4fc8656f')
      cookieProScript.async = false

      const optanonScript = document.createElement('script')
      optanonScript.textContent = 'function OptanonWrapper() { }'
      optanonScript.type = 'text/javascript'
      optanonScript.async = false

      // We want to insert the CookiePro script before the first script in the HEAD
      const firstScript = document.getElementsByTagName('script')[0]
      if (firstScript) {
        firstScript.parentNode?.insertBefore(cookieProScript, firstScript)
        firstScript.parentNode?.insertBefore(optanonScript, firstScript)
      } else {
        document.head.appendChild(cookieProScript)
        document.head.appendChild(optanonScript)
      }

      cookieProScript.setAttribute('nonce', nonce)
      optanonScript.setAttribute('nonce', nonce)

      return [cookieProScript, optanonScript]
    }

    const [cookieScript, optanonScript] = loadCookieProScript()

    return () => {
      if (cookieScript && cookieScript.parentNode) {
        cookieScript.parentNode.removeChild(cookieScript)
      }
      if (optanonScript && optanonScript.parentNode) {
        optanonScript.parentNode.removeChild(optanonScript)
      }
    }
  }, [])

  useEffect(() => {
    const cookieProScript = document.getElementById('cookie-pro-script')
    const optanonScript = document.getElementById('optanon-script')
    cookieProScript?.setAttribute('nonce', nonce)
    optanonScript?.setAttribute('nonce', nonce)
  }, [nonce])

  return null
}

export default CookiePro
