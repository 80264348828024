'use client'
import Script from 'next/script'
interface GTMProps {
  nonce: string
}

const Gtm = ({ nonce }: GTMProps) => {
  if (process.env.NODE_ENV !== 'production') {
    return null
  }

  const GTM_ID = 'GTM-M4765J'

  return (
    <>
      <noscript>
        <iframe
          nonce={nonce}
          src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      {/* This script also pulls in the following scripts from GTM: Tiktok Analytics, Scarab, Pixie.js, FacebookConnect, Bat Bing and HotJar */}
      {/* Google Tag Manager */}
      <Script
        defer
        data-nonce={nonce}
        id="gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
          n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GTM_ID}');
        `
        }}
      />
    </>
  )
}

export default Gtm
